import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Preloader from '../../Preloader';

import * as routes from '../../../constants/routes';
import { TRACK_CLICK_CLASS } from '../../../constants/const';

import {
  analytics,
  helpers,
  getCustomRoute,
  identification,
} from '../../../actions';
import TopBlock from './blocks/TopBlock';
import BrandsBlock from './blocks/BrandsBlock';

const VerifyAppVerification = (props) => {
  const { source, returnType } = props;
  const history = useHistory();
  const { state } = useLocation();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticTexts = useSelector((state) => state.textsState.data);
  const returnTo = useSelector(
    (state) => state.sessionDBState.data.urlQuery.return_to
  );

  const pageUrl = window.location.href;

  const boxTitle =
    staticTexts.VerifyAppVerificationTitle ??
    `Welcome to WeSalute, ${accountData?.firstname ? accountData.firstname : 'Unknown'}!`;
  const boxSubTitle =
    staticTexts.VerifyAppVerificationSubtitle ??
    'Your account has been created';
  const boxDescription =
    staticTexts.VerifyAppVerificationDescription ??
    'WeSalute serves members of the greater military community with discounts and benefits to enhance your lifestyle.';
  const buttonVerifyAppVerification =
    staticTexts.VerifyAppVerificationButton ??
    'Verify Your Military Affiliation';
  const buttonSkipVerification =
    staticTexts.VerifyAppVerificationButtonSkip ??
    'Skip, and browse the Marketplace';

  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const styleOverride = {};

  const customStyles = {
    ...defaultDashboardStyles,
    ...defaultFormStyles,
    ...styleOverride,
  };
  const useStyles = makeStyles((theme) => ({
    ...customStyles,
    wrapper: {
      padding: '0 24px 60px',
      letterSpacing: 0,
    },
    inner: {
      position: 'relative',
    },
    welcome: {
      marginBottom: 48,
    },
    title: {
      margin: 0,
      fontSize: 20,
      lineHeight: 1.3,
      fontWeight: 400,
      letterSpacing: '-0.02em',
      marginBottom: 5,
      [theme.breakpoints.up('md')]: {
        fontSize: 38,
        lineHeight: 1.3158,
        marginBottom: 8,
      },
    },
    subTitle: {
      fontSize: 16,
      lineHeight: 1.5625,
      fontWeight: 700,
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: '1px solid #F0F1F4',
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
        lineHeight: 1.5556,
      },
    },
    info: {
      fontSize: 14,
      lineHeight: 1.21429,
      fontStyle: 'normal',
      fontWeight: 400,
      color: '#677796',
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: 1.1875,
      },
    },
    buttonVerify: {
      ...defaultFormStyles.button,
      textTransform: 'initial',
      background: '#FFC43E',
      color: theme.palette.primary.main,
      fontSize: 16,
      lineHeight: 1.625,
      fontWeight: 700,
      paddingLeft: 10,
      paddingRight: 10,
      display: 'block',
      marginTop: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 280,
      '&:hover': {
        color: '#fff',
      },
    },
    buttonSkip: {
      ...defaultFormStyles.button,
      textTransform: 'initial',
      fontSize: 16,
      lineHeight: 1.625,
      fontWeight: 700,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 13,
      paddingBottom: 13,
      display: 'block',
      marginTop: 16,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 280,
    },
  }));
  const classes = useStyles();

  /**
   * Call to Action Clicked segment event tracking.
   * @see CX-2867.
   */
  useEffect(() => {
    document.body.addEventListener('click', (event) => {
      if (event?.target?.classList.contains(TRACK_CLICK_CLASS)) {
        const { target } = event;
        const properties = {
          label: target?.innerText !== '' ? target?.innerText : target?.alt,
          action: target?.getAttribute('data-segment'),
          type: target?.localName === 'img' ? 'banner' : 'button',
          url: pageUrl,
        };

        analytics.track('Call to Action Clicked', properties);
      }
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const [redirected, setRedirected] = useState(false);

  const followVerifyApp = () => {
    let redirectUrl = helpers.getAppUrl('verify');
    if (returnTo) {
      redirectUrl += '?return_to=' + encodeURIComponent(returnTo);
    }
    setRedirected(true);
    window.location.href = redirectUrl;
  };

  const skipVerification = () => {
    history.push(routes.VERIFY);
  };

  /**
   * Page protection.
   */
  useEffect(() => {
    if (!authUser && !redirected) {
      getCustomRoute(history);
    }
  }, [authUser]);

  /**
   * Load firstname.
   */
  useEffect(() => {
    // Skip this page for MilVetID and VerifyApp.
    if (
      source === 'MilVetID' ||
      source === 'VerifyApp' ||
      returnType === 'hydra' ||
      identification.isFullyVerified(accountData)
    ) {
      history.push(routes.VERIFY);
    } else if (accountData && accountData?.firstname) {
      setLoading(false);
    }
  }, [accountData, staticTexts]);

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <>
        <div className={classes.wrapper}>
          <div className={classes.inner}>
            <div className={classes.welcome}>
              <Typography variant="h1" className={classes.title} component="h1">
                {boxTitle}
              </Typography>
              <Typography
                variant="body2"
                className={classes.subTitle}
                component="h2"
              >
                {boxSubTitle}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.info}
                component="p"
              >
                {boxDescription}
              </Typography>
            </div>
            <TopBlock />
            <BrandsBlock />
            <Button
              className={[classes.buttonVerify, TRACK_CLICK_CLASS].join(' ')}
              onClick={followVerifyApp}
              variant={'contained'}
              color={'primary'}
              disableElevation
              fullWidth
              disabled={false}
              data-id="verify-app-verification-button"
              data-segment="get-verified"
              size="large"
            >
              <React.Fragment>{buttonVerifyAppVerification}</React.Fragment>
            </Button>
            {!state?.isOfferPage && (
              <Button
                className={[classes.buttonSkip, TRACK_CLICK_CLASS].join(' ')}
                onClick={skipVerification}
                variant={'outlined'}
                color={'secondary'}
                disableElevation
                fullWidth
                disabled={false}
                data-id="skip-verify-app-verification-button"
                data-segment="goto-marketplace"
                size="large"
              >
                <React.Fragment>{buttonSkipVerification}</React.Fragment>
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default VerifyAppVerification;
