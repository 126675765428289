/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import EmailDialog from '../EmailDialog/EmailDialogContainer';
import Preloader from '../../Preloader';
import * as routes from '../../../constants/routes';
import { api, auth, db, getCustomRoute, helpers } from '../../../actions';
import {
  authRef,
  facebook_provider,
  google_provider,
} from '../../../firebase/firebase';

const SignUpWithRedirect = (props) => {
  const params = useParams();
  const provider =
    params?.provider === 'facebook'
      ? facebook_provider
      : google_provider;
  const { setErrorPage } = db;

  const history = useHistory();
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticTexts = useSelector((state) => state.textsState.data);

  const [showEmailForm, setShowEmailForm] = useState(false);
  const [signAuthUser, setSignAuthUser] = useState(null);
  const [authUserEmail, setAuthUserEmail] = useState(null);
  const [authUserFirstName, setAuthUserFirstName] = useState(null);
  const [authUserLastName, setAuthUserLastName] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Redirect to social network login screen.
    if (!authUser) {
      signInWithRedirect();
      return;
    }

    // Process redirect result.
    authRef
      .getRedirectResult()
      .then(async (authUser) => {
        dispatch(db.fetchAccountInfo(authUser?.user?.uid));
        // Check if veterans record exists, if yes, then restrict account deletion.
        // Sign in the user with Facebook provider.
        const veteransRecord = await db.loadVeteranRecord(authUser?.user?.uid);
        // Set corresponding state.
        setIsNewUser(!veteransRecord?.memberId);
        // Prepare variables.
        const email = authUser.user?.email;
        const firstName =
          veteransRecord?.firstname ??
          authUser.additionalUserInfo?.profile?.first_name ??
          authUser.additionalUserInfo?.profile?.given_name;
        const lastName =
          veteransRecord?.lastname ??
          authUser.additionalUserInfo?.profile?.last_name ??
          authUser.additionalUserInfo?.profile?.family_name;
        // If email is not provided or first or last name are not valid.
        if (
          !email ||
          !firstName ||
          !lastName ||
          !helpers.isValidName(firstName) ||
          !helpers.isValidName(lastName)
        ) {
          setSignAuthUser(authUser);
          // Fill data if valid.
          if (email) {
            setAuthUserEmail(authUser.user.email);
          }
          if (firstName && helpers.isValidName(firstName)) {
            setAuthUserFirstName(firstName);
          }
          if (lastName && helpers.isValidName(lastName)) {
            setAuthUserLastName(lastName);
          }
          setLoading(false);
          setShowEmailForm(true);
        }
        // Create veterans record if none created before.
        else if (!veteransRecord?.memberId) {
          checkNewUser(authUser, email, firstName, lastName);
        }
      })
      .catch((error) => {
        if (error.code !== 'auth/popup-closed-by-user') {
          Sentry.captureException(error);
        }
        console.error('social signup error', error);

        if (error.code === 'auth/operation-not-supported-in-this-environment') {
          dispatch(
            setErrorPage(
              staticTexts.IncognitoModeErrorTitle,
              staticTexts.IncognitoModeErrorDescription,
              'error',
              false,
              staticTexts.GeneralErrorClose,
              false,
              false,
              true
            )
          );
        } else if (error.email) {
          // Process Firebase Auth existing accounts messages.
          // The error object will contain the email for the account.
          auth
            .processSignInMethodsForEmail(
              error.email,
              staticTexts,
              dispatch,
              setErrorPage,
              error
            )
            .catch((error) => console.error(error));
        } else {
          dispatch(
            setErrorPage(
              staticTexts.GeneralErrorTitle,
              error.message,
              'error',
              false,
              staticTexts.GeneralErrorClose,
              false,
              false,
              true
            )
          );
        }
      });
  }, []);

  /**
   * Redirect user to the next login page.
   *
   * Usually in case of Facebook login next page
   * is an email verification for newly added user.
   */
  useEffect(() => {
    if (accountData) {
      if (
        helpers.isValidName(accountData?.firstname) &&
        helpers.isValidName(accountData?.lastname) &&
        accountData?.roles &&
        accountData?.memberId
      ) {
        setLoading(false);
        getCustomRoute(history);
      }
    }
  }, [accountData]);

  const signInWithRedirect = () => {
    setLoading(true);
    auth.doSignInWithRedirect(provider);
  };

  const checkNewUser = (authUser, email, firstName, lastName) => {
    // Check if user had Drupal based account with populated facebookId field.
    // Check  if veterans collection has facebookId equal to current user.
    helpers
      .checkExistingUser(email ? email : authUser.user.email, null, true)
      .then(async ({ data }) => {
        setShowEmailForm(false);
        if (!data.exist) {
          createNewUser(authUser, email, firstName, lastName);
        } else if (data?.id === authUser.user.uid) {
          await helpers.addAccountInfo(
            {
              firstname: firstName,
              lastname: lastName,
              email: email,
              emailVerified: !!authUser.user?.emailVerified,
            },
            authUser.user.uid
          );
          // Redirect to next step upon success.
        } else {
          // Show delete message only if different ids detected.
          deleteCurrentUser(authUser);
        }
      })
      .then(() => {
        // Redirect to next step upon success.
        dispatch(db.fetchAccountInfo(authUser?.user?.uid));
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('facebook-check-existing-user', error);
        deleteCurrentUser(authUser);
      });
  };

  const createNewUser = async (authUser, email, firstName, lastName) => {
    const idToken = await signAuthUser.user.getIdToken();
    const userData = {
      first_name: firstName,
      last_name: lastName,
    };
    api.createProfile(userData, idToken)
      .then(() => db.fetchAccountInfo(authUser?.user?.uid))
      .catch((error) => {
        Sentry.captureException(error);
        console.error('social-create-new-user', error);
        const apiError =
          error?.response?.data?.error?.message ?? error?.message;

        // Set state error with the catch error.
        dispatch(
          setErrorPage(
            staticTexts.GeneralErrorTitle,
            apiError,
            'error',
            false,
            staticTexts.GeneralErrorClose,
            false,
            false,
            true
          )
        );
      });
  };

  const deleteCurrentUser = (authUser) => {
    authUser.user
      .delete()
      .then(() => {
        dispatch(
          setErrorPage(
            staticTexts[provider === 'facebook' ? 'FacebookErrorExistingAccountTitle' : 'GoogleErrorExistingAccountTitle'],
            staticTexts[provider === 'facebook' ? 'FacebookErrorExistingAccountDescription' : 'GoogleErrorExistingAccountDescription'],
            'warning',
            false,
            staticTexts[provider === 'facebook' ? 'FacebookErrorExistingAccountButton' : 'GoogleErrorExistingAccountButton'],
            routes.SIGN_OUT
          )
        );
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  };

  const onSubmitEmail = (email, firstName, lastName) => {
    setLoading(true);
    // Create a user in Firebase Database if it is a new user with retrieve before auth user and provided email.
    checkNewUser(signAuthUser, email, firstName, lastName);
  };

  const goToSignIn = () => {
    setShowEmailForm(false);
    history.push(routes.SIGN_IN);
  };

  return (
    <>
      {loading && <Preloader waitFor={300000} />}
      <EmailDialog
        isNewUser={isNewUser}
        showEmailForm={showEmailForm}
        authUserEmail={authUserEmail}
        authUserFirstName={authUserFirstName}
        authUserLastName={authUserLastName}
        onSubmitEmail={onSubmitEmail}
        goToSignIn={goToSignIn}
      />
    </>
  );
};

export default SignUpWithRedirect;
